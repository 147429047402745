/**
 * Represents a combined grid option template data transfer object.
 */
export class CombinedGridOptionTemplateDTO {
    /**
     * The name of the table.
     */
    public tableName: string = "";

    /**
     * The icon associated with the table.
     */
    public tableIcon: string = "";

    /**
     * The label to hide the list.
     */
    public hideListLabel: string = "";

    /**
     * The character used as an icon.
     */
    public iconCharacter: string = "";
}
