import { DatalistTemplate } from "../../../../../record/core/domain/datalistTemplate/datalist-template";
import { DatalistTemplateDTO } from "../../../../../record/data/models/datalist/datalist-template.dto";
import { Node } from "./node";
export class Graph<T extends DatalistTemplateDTO | DatalistTemplate> {
    nodes: Map<number, Node<T>>;
    constructor(nodes: Node<T>[]) {
        this.nodes = new Map<number, Node<T>>();
        for (const node of nodes) {
            this.nodes.set(node.data.datalistID, node);
        }
    }

    addNode(node: Node<T>) {
        this.nodes.set(node.data.datalistID, node);
    }

    addEdge(source: Node<T>, destination: Node<T>) {
        // Directed graph
        source.addNeighbor(destination);
    }

    getNodeByID(id: number): Node<T> {
        // TODO: Add null input and output checks
        return this.nodes.get(id)!;
    }

    topologicalSort(): Node<T>[] {
        const visited: Map<number, boolean> = new Map<number, boolean>();
        const stack: Node<T>[] = [];
        this.nodes.forEach((node) => {
            if (!visited.get(node.data.datalistID)) {
                this.topologicalSortUtil(node, visited, stack);
            }
        });
        return stack;
    }

    topologicalSortUtil(
        node: Node<T>,
        visited: Map<number, boolean>,
        stack: Node<T>[]
    ) {
        visited.set(node.data.datalistID, true);
        node.neighbors.forEach((neighbor) => {
            if (!visited.get(neighbor.data.datalistID)) {
                this.topologicalSortUtil(neighbor, visited, stack);
            }
        });
        stack.unshift(node);
    }
}
