/**
 * Represents a LargeObjectDTO.
 */
/**
 * Represents a LargeObjectDTO.
 */
export class LargeObjectDTO {
    /**
     * The field instance ID.
     */
    public fieldInstanceID: number = 0;

    /**
     * The value of the large object.
     */
    public value: string = "";

    /**
     * The display value of the large object.
     */
    public displayValue: string = "";

    /**
     * The type of the large object.
     */
    public type: string = "";

    /**
     * The checksum of the large object.
     */
    public checksum: string = "";

    /**
     * The record instance ID.
     */
    public recordInstanceID: number = 0;
}
