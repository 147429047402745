import { NgModule } from "@angular/core";
import { RouterModule, Routes, RouterOutlet } from "@angular/router";
import { authGuard } from "./core/auth/infrastructure/route-guard/auth.guard";

const routes: Routes = [
    {
        path: "",
        canActivate: [authGuard],
        loadChildren: () =>
            import("./main/main.module").then((m) => m.MainModule),
    },
    {
        path: "login",
        loadChildren: () =>
            import("./login/login.module").then((m) => m.LoginModule),
    },
    {
        path: "**",
        redirectTo: "/home",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule, RouterModule.forRoot(routes)],
    exports: [RouterModule, RouterOutlet],
})
export class AppRoutingModule {}
