import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { IMirrorDependencyOption } from "./IMirror-dependency-option";

export class UserMirrorDependencyOption implements IMirrorDependencyOption {
    fieldName: string = "";
    fieldID: number = 0;
    mirrorType: MirrorOptionType = MirrorOptionType.User;
    userPropertyName: string = "";
    mirrorLabel!: string;
}
