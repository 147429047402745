import { inject, Injectable } from "@angular/core";
import { IIndexable } from "../../../core/base/indexable.interface";
import { UserDTO } from "../model/user.dto";
import { JsonOrganizationMapper } from "./json-organization.mapper";
import { Mapper } from "../../../core/base/mapper";

@Injectable({
    providedIn: "root",
})
export class JsonUserMapper extends Mapper<IIndexable, UserDTO> {
    private jsonOrganizationMapper: JsonOrganizationMapper = inject(
        JsonOrganizationMapper
    );

    /**
     * Maps a JSON object to a UserDTO.
     * @param json The JSON object to map from.
     * @returns The mapped UserDTO.
     */
    mapFrom(json: IIndexable): UserDTO {
        const {
            userID,
            organizationID,
            organizationRoleIDs,
            organization,
            ...otherProperties
        } = json;

        const dto: UserDTO = {
            ...new UserDTO(),
            ...otherProperties,
            id: userID,
            organizationId: organizationID,
            organizationRoleIds: organizationRoleIDs,
            organization: this.jsonOrganizationMapper.mapFrom(
                organization ?? {}
            ),
        };

        return dto;
    }

    /**
     * Maps a UserDTO to a JSON object.
     * @param dto The UserDTO to map from.
     * @returns The mapped JSON object.
     */
    mapTo(dto: UserDTO): IIndexable {
        const { id, organizationId, organizationRoleIds, ...otherProperties } =
            dto;

        const json: IIndexable = {
            ...otherProperties,
            userID: id,
            organizationID: organizationId,
            organizationRoleIDs: organizationRoleIds,
            organization: this.jsonOrganizationMapper.mapTo(dto.organization),
        };

        return json;
    }
}
