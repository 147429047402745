import { Injectable } from "@angular/core";
import { OrganizationDTO } from "../model/organization.dto";
import { Mapper } from "../../../core/base/mapper";
import { IIndexable } from "../../../core/base/indexable.interface";

@Injectable({
    providedIn: "root",
})
export class JsonOrganizationMapper extends Mapper<
    IIndexable | null,
    OrganizationDTO
> {
    /**
     * Maps a JSON object to a OrganizationDTO.
     * @param json The JSON object to map from.
     * @returns The mapped OrganizationDTO.
     */
    mapFrom(json: IIndexable): OrganizationDTO {
        const dto: OrganizationDTO = new OrganizationDTO();

        dto.id = json["organizationID"];
        dto.name = json["organizationName"];
        dto.unit = json["organizationUnit"];

        return dto;
    }

    /**
     * Maps a OrganizationDTO to a JSON object.
     * @param organizationDTO The OrganizationDTO to map from.
     * @returns The mapped JSON object.
     */
    mapTo(dto: OrganizationDTO | null): IIndexable | null {
        if (dto === null) {
            return null;
        }

        const json: IIndexable = {
            organizationID: dto?.id,
            organizationName: dto?.name,
            organizationUnit: dto?.unit,
        };

        return json;
    }
}
