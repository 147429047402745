import { inject, Injectable } from "@angular/core";
import { Usecase } from "../../../base/usecase";
import { SystemSettings } from "../domain/system-settings.model";
import { SystemSettingsStore } from "../../infrastructure/stores/system-settings.store";

@Injectable({
    providedIn: "root",
})
export class LoadSystemSettingsUsecase extends Usecase<
    void,
    Promise<SystemSettings[]>
> {
    private _systemSettingsStore: SystemSettingsStore =
        inject(SystemSettingsStore);

    /**
     * Load system settings from store if any failure retry
     * @returns
     */
    override async execute(): Promise<SystemSettings[]> {
        return await this._systemSettingsStore.load();
    }
}
