import {
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpHandlerFn,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { inject } from "@angular/core";
import { SystemSettingsJsonDtoMapper } from "../../data/transformations/system-settings-json-dto.mapper";
import { SystemSettingsJSON } from "../../data/models/response/system-settings.response";
import { environment } from "../../../../../environments/environment";

export const systemSettingsInterceptor = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const mapper: SystemSettingsJsonDtoMapper = inject(
        SystemSettingsJsonDtoMapper
    );

    // Return if does not match our interceptor rule
    if (
        !(request instanceof HttpRequest) ||
        request.url === "" ||
        request.url == null ||
        !environment.routes.mCase.routes.systemSettings.url.includes(
            request.url
        )
    ) {
        return next(request);
    }

    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            // Return if does not match our interceptor rule
            if (
                !(response instanceof HttpResponse) ||
                response.url === "" ||
                response.url == null ||
                !environment.routes.mCase.routes.systemSettings.url.includes(
                    response.url
                )
            ) {
                return response;
            }

            const start = performance.now();

            // Transform response body from JSON to DTO
            if (response instanceof HttpResponse) {
                response = response.clone({
                    body: mapper.mapFrom(response.body as SystemSettingsJSON),
                });
            }

            const end = performance.now();
            console.log(`Request took ${end - start} milliseconds`);
            return response;
        })
    );
};
