import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BOOLEAN_UTILITIES } from "../../../shared/injectionTokens/injection-tokens";
import { ChildDatalistRelationshipTemplateDTO } from "../../../record/data/models/datalist/childDatalistRelationship/child-datalist-relationship-template.dto";
import { ClientSideEventTemplateDTO } from "../../../record/data/models/datalist/clientEvents/client-side-event-template.dto";
import { ClientWorkflowOptionsDTO } from "../../../record/data/models/datalist/clientEvents/client-workflow-options.dto";
import { CombinedGridOptionTemplateDTO } from "../../../record/data/models/datalist/combined-grid-option-template.dto";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { LabelTemplateDTO } from "../../../record/data/models/datalist/label-template.dto";
import { OptionTemplateDTO } from "../../../record/data/models/datalist/option-template.dto";
import { SearchableDescendantListsTemplateDTO } from "../../../record/data/models/datalist/searchable-descendant-lists-template.dto";
import { HeaderFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/header-field-template.dto";
import { SectionFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/section-field-template.dto";
import { FieldValidatorDTO } from "../../data/model/fieldValidator/fieldValidator.dto";
import { FieldDependencyOptionDTO } from "../../data/model/fieldDependencyOption/field-dependency-option.dto";
import { FieldDependencyDTO } from "../../data/model/fieldDependencyOption/field-dependency.dto";
import { FieldDependencyTypes } from "../../domain/enums/field-dependency-types.enum";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { FieldValidators } from "../../domain/enums/field-validators.enum";
import { ConditionallyMandatory } from "../../../record/data/models/options/conditionally-mandatory";
import { CascadingDropdownFieldValue } from "../../domain/dropdownValues/cascading-drop-down-field-value.model";
import { DropdownFieldValue } from "../../domain/dropdownValues/drop-down-field-value.model";
import { DropdownScoreValue } from "../../domain/dropdownValues/drop-down-score-value.model";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { SortDirection } from "../../domain/enums/sort-direction.enum";
import { MirrorDependency } from "../../../record/core/domain/mirroring/mirror-dependency";
import { Graph } from "../../loadProcess/data/model/templateGraph/graph";
import { Node } from "../../loadProcess/data/model/templateGraph/node";
import { MirrorOptionType } from "../../domain/enums/mirror-option-type.enum";
import { DynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-dropdown-field-template.dto";
import { UserMirrorDependencyOption } from "../../../record/core/domain/mirroring/user-mirror-dependency-option";
import { DynamicMirrorDependencyOption } from "../../../record/core/domain/mirroring/dynamic-mirror-dependency-option";
import { RegularMirrorDependencyOption } from "../../../record/core/domain/mirroring/regular-mirror-dependency-option";
import { ParentMirrorDependencyOption } from "../../../record/core/domain/mirroring/parent-mirror-dependency-option";
import { LOGGER } from "../../logging/providers/logger.provider";
import { MirrorFormulaConstants } from "../../domain/enums/mirror-formula-constants.enum";
import { SoundsLikeValues } from "../../domain/enums/soundsLike-values.enum";
import {
    MirrorFieldMap,
    MirrorFieldTemplateDTOArray,
} from "../../domain/enums/mirror-types.enum";
import { IconTemplateDTO } from "../../../record/data/models/datalist/icon-template.dto";
import { ServerTemplateParseService } from "../../loadProcess/services/server-parse-template.service";

//TODO: Switch to promise based interceptor if needed.
export const templateInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const booleanConverter = inject(BOOLEAN_UTILITIES);
    const logger = inject(LOGGER);
    const serverTemplateParseService = inject(ServerTemplateParseService);

    const mCaseRoutes = environment.routes.mCase.routes;
    const approvedRoute = mCaseRoutes.getTemplates.url;
    //#region Interceptor
    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            const start = performance.now();

            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !response.url.includes(approvedRoute)
            ) {
                return response;
            }

            // Process all templates
            response = response.clone({
                body: serverTemplateParseService.transformTemplates(
                    response.body
                ),
            });

            const end = performance.now();
            console.log(
                `Template Interceptor took ${end - start} milliseconds.`
            );
            return response;
        })
    );
    //#endregion
};
