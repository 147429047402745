/**
 * Represents a conditionally mandatory field.
 */
export class ConditionallyMandatory {
    /**
     * The field that mandates the presence of this field.
     */
    mandatedByField!: string;

    /**
     * The value of the field that mandates the presence of this field.
     */
    mandatedByValue!: string;

    /**
     * Indicates whether this field is mandated only if the `mandatedByField` has a value.
     */
    mandatedIfFieldHasValue!: boolean;

    /**
     * The name of the system that mandates the presence of this field.
     */
    mandatedBySystemName!: string;
}
