/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from "@angular/core";
import { BOOLEAN_UTILITIES } from "../../../shared/injectionTokens/injection-tokens";
import { LOGGER } from "../../logging/providers/logger.provider";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import {
    MirrorFieldMap,
    MirrorFieldTemplateDTOArray,
} from "../../domain/enums/mirror-types.enum";
import { HttpEvent } from "@angular/common/http";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { DynamicMirrorDependencyOption } from "../../../record/core/domain/mirroring/dynamic-mirror-dependency-option";
import { MirrorDependency } from "../../../record/core/domain/mirroring/mirror-dependency";
import { ParentMirrorDependencyOption } from "../../../record/core/domain/mirroring/parent-mirror-dependency-option";
import { RegularMirrorDependencyOption } from "../../../record/core/domain/mirroring/regular-mirror-dependency-option";
import { UserMirrorDependencyOption } from "../../../record/core/domain/mirroring/user-mirror-dependency-option";
import { ChildDatalistRelationshipTemplateDTO } from "../../../record/data/models/datalist/childDatalistRelationship/child-datalist-relationship-template.dto";
import { ClientSideEventTemplateDTO } from "../../../record/data/models/datalist/clientEvents/client-side-event-template.dto";
import { ClientWorkflowOptionsDTO } from "../../../record/data/models/datalist/clientEvents/client-workflow-options.dto";
import { CombinedGridOptionTemplateDTO } from "../../../record/data/models/datalist/combined-grid-option-template.dto";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { IconTemplateDTO } from "../../../record/data/models/datalist/icon-template.dto";
import { LabelTemplateDTO } from "../../../record/data/models/datalist/label-template.dto";
import { OptionTemplateDTO } from "../../../record/data/models/datalist/option-template.dto";
import { SearchableDescendantListsTemplateDTO } from "../../../record/data/models/datalist/searchable-descendant-lists-template.dto";
import { DynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-dropdown-field-template.dto";
import { HeaderFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/header-field-template.dto";
import { SectionFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/section-field-template.dto";
import { ConditionallyMandatory } from "../../../record/data/models/options/conditionally-mandatory";
import { FieldDependencyOptionDTO } from "../../data/model/fieldDependencyOption/field-dependency-option.dto";
import { FieldDependencyDTO } from "../../data/model/fieldDependencyOption/field-dependency.dto";
import { FieldValidatorDTO } from "../../data/model/fieldValidator/fieldValidator.dto";
import { CascadingDropdownFieldValue } from "../../domain/dropdownValues/cascading-drop-down-field-value.model";
import { DropdownFieldValue } from "../../domain/dropdownValues/drop-down-field-value.model";
import { DropdownScoreValue } from "../../domain/dropdownValues/drop-down-score-value.model";
import { FieldDependencyTypes } from "../../domain/enums/field-dependency-types.enum";
import { FieldValidators } from "../../domain/enums/field-validators.enum";
import { MirrorFormulaConstants } from "../../domain/enums/mirror-formula-constants.enum";
import { MirrorOptionType } from "../../domain/enums/mirror-option-type.enum";
import { SoundsLikeValues } from "../../domain/enums/soundsLike-values.enum";
import { Graph } from "../data/model/templateGraph/graph";
import { Node } from "../data/model/templateGraph/node";
import { ColumnWidthDisplayOptions } from "../../../record/data/models/options/column-width-display-options.enum";
import { FieldSearchConfiguration } from "../../domain/enums/default-search-configuration";

@Injectable({
    providedIn: "root",
})
export class ServerTemplateParseService {
    booleanConverter = inject(BOOLEAN_UTILITIES);
    logger = inject(LOGGER);

    isDefaultValueMirror(fieldTemplate: any): boolean {
        if (
            Object.prototype.hasOwnProperty.call(
                fieldTemplate,
                "defaultValue"
            ) &&
            typeof fieldTemplate.defaultValue !== "undefined" &&
            fieldTemplate.defaultValue !== null &&
            fieldTemplate.defaultValue !== "" //skip empty string
        ) {
            //May be a mirror if default value contains start and end marker
            return (
                fieldTemplate.defaultValue.includes("{[") &&
                fieldTemplate.defaultValue.includes("]}")
            );
        }

        return false;
    }

    fieldMap = new Map([
        ["address", FieldTypes.Address],
        ["attachment", FieldTypes.Attachment],
        ["boolean", FieldTypes.Boolean],
        ["calculatedfield", FieldTypes.CalculatedField],
        ["cascadingdropdown", FieldTypes.CascadingDropdown],
        ["cascadingdynamicdropdown", FieldTypes.CascadingDynamicDropdown],
        ["checkbox", FieldTypes.Checkbox],
        ["datalist", FieldTypes.Datalist],
        ["date", FieldTypes.Date],
        ["datetime", FieldTypes.DateTime],
        ["dropdown", FieldTypes.Dropdown],
        ["dropdownlist", FieldTypes.Dropdown],
        ["dynamiccalculatedfield", FieldTypes.DynamicCalculatedField],
        ["dynamicdropdown", FieldTypes.DynamicDropdown],
        ["emailaddress", FieldTypes.EmailAddress],
        ["embeddeddocument", FieldTypes.EmbeddedDocument],
        ["embeddedlist", FieldTypes.EmbeddedList],
        ["externalobject", FieldTypes.ExternalObject],
        ["longstring", FieldTypes.LongString],
        ["linebreak", FieldTypes.LineBreak],
        ["maxscore", FieldTypes.MaxScore],
        ["minscore", FieldTypes.MinScore],
        ["money", FieldTypes.Money],
        ["narrative", FieldTypes.Narrative],
        ["number", FieldTypes.Number],
        ["phone", FieldTypes.Phone],
        ["readonlyfield", FieldTypes.ReadOnly],
        ["score1", FieldTypes.Score1],
        ["score2", FieldTypes.Score2],
        ["score3", FieldTypes.Score3],
        ["score4", FieldTypes.Score4],
        ["score5", FieldTypes.Score5],
        ["score6", FieldTypes.Score6],
        ["string", FieldTypes.String],
        ["time", FieldTypes.Time],
        ["uniqueidentifier", FieldTypes.UniqueIdentifier],
        ["url", FieldTypes.Url],
        ["user", FieldTypes.User],
        ["userrolesecurityrestrict", FieldTypes.UserRoleSecurityRestrict],
        ["userroles", FieldTypes.UserRoles],
    ]);
    transformFieldType(fieldTemplate: any): string {
        let fieldType = fieldTemplate.addType;
        // Remove spaces
        fieldType = fieldType.replace(/\s/g, "");
        fieldType = fieldType.replace(/-/g, "");

        if (this.fieldMap.has(fieldType.toLowerCase())) {
            fieldType = this.fieldMap.get(fieldType.toLowerCase());
        } else {
            throw `Field type ${fieldType} not found`;
        }

        if (this.isDefaultValueMirror(fieldTemplate)) {
            // mirror field type
            fieldType = MirrorFieldMap.get(fieldType);
        }
        return fieldType;
    }
    transformResponseToDatalistDTO(
        responseBody: HttpEvent<any>,
        graph: Graph<DatalistTemplateDTO>
    ): any {
        // Create a new Datalist DTO object
        const template: DatalistTemplateDTO = new DatalistTemplateDTO();

        // Clone response body
        const responseBodyCopy = { ...responseBody };
        Object.entries(responseBodyCopy).forEach(([key, value]) => {
            if (key === "listId") {
                template.datalistID = value;
            }
        });
        graph.addNode(new Node<DatalistTemplateDTO>(template));
        // Go over each property in the response body
        Object.entries(responseBodyCopy).forEach(([key, value]) => {
            if (value === null || value === undefined) {
                return;
            }

            if (key === "sections") {
                // Create our section => header => field template structure
                template.sections = this.transformDataToSectionTemplateDTO(
                    value,
                    graph
                );
            } else if (key === "optionTemplate") {
                template.optionTemplate =
                    this.transformDataToOptionTemplateDTO(value);
            } else if (key === "labelTemplate") {
                // Label Template
                template.labelTemplate = Object.assign(
                    new LabelTemplateDTO(),
                    value
                );
            } else if (key === "children") {
                // Children
                template.children = value.map((child: any) => {
                    for (const prop in child) {
                        if (Object.prototype.hasOwnProperty.call(child, prop)) {
                            const camelCaseProp =
                                prop[0].toLowerCase() + prop.slice(1);
                            if (camelCaseProp != prop) {
                                child[camelCaseProp] = child[prop];
                                delete child[prop];
                            }
                        }
                    }
                    const childDatalistRelationshipTemplateDTO = Object.assign(
                        new ChildDatalistRelationshipTemplateDTO(),
                        child
                    );
                    // graph.addEdge(graph.getNodeByID(template.datalistID), graph.getNodeByID(childDatalistRelationshipTemplateDTO.childListID));
                    // graph.getNodeByID
                    return childDatalistRelationshipTemplateDTO;
                });
            } else if (key === "listLabel") {
                template.name = value;
            } else if (key === "dataListDescription") {
                template.description = value;
            } else if (key === "listId") {
                template.datalistID = value;
            } else if (key === "sortOrder") {
                template.sortOrder = value;
            } else if (key === "readOnlyChildren") {
                template.readOnlyChildren = value;
            } else if (key === "checksum") {
                template.checksum = value;
            } else if (key === "allowAdd") {
                template.allowAdd =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowModify") {
                template.allowModify =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowDelete") {
                template.allowDelete =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowMove") {
                template.allowMove =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowActivityWall") {
                template.allowActivityWall =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowMerge") {
                template.allowMerge =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowEditOthers") {
                template.allowEditOthers =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "allowEditOthersDraft") {
                template.allowEditOthersDraft =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "isListAdmin") {
                template.isListAdmin =
                    this.booleanConverter.convertToBoolean(value);
            } else if (key === "isInfrastructureList") {
                template.isInfrastructureList =
                    this.booleanConverter.convertToBoolean(value);
            }
            // else if (key === "elementAccessEvaluated") {
            //     template.elementAccessEvaluated = value;
            // }
            else if (key === "adminInfo") {
                // TODO: Separate transformation might be needed
                template.adminInfo = value;
            } else if (key === "systemUsers") {
                template.systemUsers = value;
            } else if (key === "clientSideEventTemplates") {
                // TODO: Separate transformation might be needed
                const clientSideEventTemplates: ClientSideEventTemplateDTO[] =
                    value.map((clientSideEventTemplate: any) => {
                        return this.transformDataToClientSideEventTemplateDTO(
                            clientSideEventTemplate
                        );
                    });

                template.clientSideEventTemplates = clientSideEventTemplates;
            } else if (key === "searchableDescendantLists") {
                // TODO: Separate transformation might be needed
                const searchableDescendantLists: SearchableDescendantListsTemplateDTO[] =
                    value.map((searchableDescendantList: any) => {
                        return this.transformDataToSearchableDescendantListsTemplate(
                            searchableDescendantList
                        );
                    });

                template.searchableDescendantLists =
                    searchableDescendantLists as SearchableDescendantListsTemplateDTO[];
            } else if (key === "systemName") {
                template.systemName = value;
            }
        });
        return template;
    }
    transformDataToSectionTemplateDTO(
        value: any,
        graph: Graph<DatalistTemplateDTO>
    ): SectionFieldTemplateDTO[] {
        return value.map((section: any) => {
            // Go over each property in a section
            for (const prop in section) {
                // Camel case conversion
                if (Object.prototype.hasOwnProperty.call(section, prop)) {
                    const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                    if (camelCaseProp != prop) {
                        section[camelCaseProp] = section[prop];
                        delete section[prop];
                    }
                    // Create headerFieldTemplateDTO
                    if (camelCaseProp === "headers") {
                        const headers: HeaderFieldTemplateDTO[] =
                            this.transformDataToHeaderFieldTemplateDTO(
                                section,
                                graph
                            );

                        delete section.headers;
                        section.children = headers;
                    }
                }
            }

            if (section.fieldId == null) {
                // section.fieldID = Math.floor(Math.random()) // TODO: Something more specific?
                section.fieldID = null;
            } else {
                section.fieldID = section.fieldId;
            }
            // Remove older reference
            delete section.fieldId;
            return Object.assign(new SectionFieldTemplateDTO(), section);
        });
    }
    transformDataToHeaderFieldTemplateDTO(
        section: any,
        graph: Graph<DatalistTemplateDTO>
    ): HeaderFieldTemplateDTO[] {
        const headers: HeaderFieldTemplateDTO[] = [];

        section["headers"].map((header: any) => {
            for (const headerProp in header) {
                if (Object.prototype.hasOwnProperty.call(header, headerProp)) {
                    const camelCaseHeaderProp =
                        headerProp[0].toLowerCase() + headerProp.slice(1);
                    if (camelCaseHeaderProp != headerProp) {
                        header[camelCaseHeaderProp] = header[headerProp];
                        delete header[headerProp];
                    }
                    // Go over fieldTemplates
                    if (camelCaseHeaderProp === "fieldTemplates") {
                        let fieldTemplates = header[camelCaseHeaderProp];

                        // Filter out some fields. TODO: Create a new list of field types to filter out
                        const filteredTemplates = fieldTemplates.filter(
                            (ft: any) =>
                                ft.addType != "OLFSAttachment" &&
                                ft.addType != "Intake Referral ID" &&
                                ft.addType != "Current Date" &&
                                ft.addType != "Current User" &&
                                ft.addType != "Hidden Field"
                        );

                        fieldTemplates = filteredTemplates.map(
                            (fieldTemplate: any) => {
                                fieldTemplate.datalistID = section.datalistID;
                                const transformedFieldTemplate =
                                    this.transformDataToFieldTemplateDTO(
                                        fieldTemplate,
                                        graph
                                    );
                                graph
                                    .getNodeByID(fieldTemplate.datalistID)
                                    ?.addToFieldMap(transformedFieldTemplate);
                                return transformedFieldTemplate;
                            }
                        );

                        // Confirm if the type is correct
                        header.children = fieldTemplates;
                        delete header.fieldTemplates;
                    }
                }
            }
            header.fieldID = header.fieldId; // This could be null. To be determined what to do with this.
            delete header.fieldId;
            const newHeader: HeaderFieldTemplateDTO = Object.assign(
                new HeaderFieldTemplateDTO(),
                header
            );
            headers.push(newHeader);
        });

        return headers;
    }

    generateMirrorDependencyForFieldTemplate(
        fieldTemplate: FieldTemplateDTO,
        graph: Graph<DatalistTemplateDTO>
    ): void {
        const fieldDefaultValue = fieldTemplate["defaultValue"];

        if (fieldDefaultValue == null || fieldDefaultValue === "") {
            return;
        }

        // Check if the default value indicates mirroring.
        const matches = fieldDefaultValue.match(
            /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/g
        );
        // No mirror matches found
        if (matches == null) {
            return;
        }
        // Initialize mirror dependency object
        const mirrorDependency = new MirrorDependency();
        const numberOfMatches = matches.length;
        // Mirror formula
        mirrorDependency.formula = fieldDefaultValue;
        if (numberOfMatches > 1) {
            mirrorDependency.formula = mirrorDependency.formula.substring(
                2,
                mirrorDependency.formula.length - 2
            );
            fieldTemplate.type = FieldTypes.MirrorString;
        }

        // Go over matches
        matches?.forEach((innerMatch: string) => {
            const matchParts = innerMatch.match(
                /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/
            );

            if (matchParts?.groups?.["reference"] != null) {
                const referencedDatalist = graph.getNodeByID(
                    fieldTemplate.datalistID
                );

                if (referencedDatalist == null) {
                    this.logger.error(
                        `Datalist ${fieldTemplate.datalistID} not found`
                    );
                    return;
                }

                // Reference can be DDD field or user
                if (
                    matchParts.groups?.["reference"] !=
                    MirrorFormulaConstants.parent
                ) {
                    const referencedFieldOnTemplate =
                        referencedDatalist.fieldMap.get(
                            matchParts.groups?.["reference"]
                        );

                    if (referencedFieldOnTemplate == null) {
                        this.logger.error(
                            `Field ${matchParts.groups?.["reference"]} not found on datalist ${fieldTemplate.datalistID}`
                        );
                        throw new Error(
                            `Field ${matchParts.groups?.["reference"]} not found on datalist ${fieldTemplate.datalistID}`
                        );
                    }

                    // The source field is dynamic dropdown.
                    if (
                        referencedFieldOnTemplate.type ===
                        FieldTypes.DynamicDropdown
                    ) {
                        // TODO: Check if CDDD etc
                        this.logger.log(
                            `Field ${matchParts.groups?.["reference"]} found on datalist ${fieldTemplate.datalistID} and is a DDD field`
                        );
                        // Field found. Add to dependency
                        const dynamicDTO: DynamicDropdownFieldTemplateDTO =
                            referencedFieldOnTemplate as DynamicDropdownFieldTemplateDTO;

                        if (
                            dynamicDTO.source &&
                            graph.getNodeByID(dynamicDTO.source) != null
                        ) {
                            const parent = graph.getNodeByID(dynamicDTO.source);
                            const dynamicReferenceFieldTemplate =
                                parent.fieldMap.get(
                                    matchParts.groups?.["systemName"]
                                );
                            if (!dynamicReferenceFieldTemplate) {
                                throw new Error(
                                    `Field ${matchParts.groups?.["systemName"]} not found on datalist ${parent.data.datalistID}`
                                );
                            }
                            // Add type check
                            const mirrorDependencyField =
                                new DynamicMirrorDependencyOption();
                            mirrorDependencyField.fieldName =
                                referencedFieldOnTemplate.systemName;
                            mirrorDependencyField.fieldID =
                                referencedFieldOnTemplate.fieldID!;
                            mirrorDependencyField.mirrorType =
                                MirrorOptionType.Dynamic;
                            mirrorDependencyField.dynamicDatalistID =
                                parent.data.datalistID;
                            mirrorDependencyField.dynamicDatalistSystemName =
                                parent.data.systemName;
                            mirrorDependencyField.dynamicDatalistFieldID =
                                dynamicReferenceFieldTemplate.fieldID!;
                            mirrorDependencyField.dynamicDatalistFieldName =
                                dynamicReferenceFieldTemplate.systemName;
                            mirrorDependencyField.mirrorLabel = innerMatch;
                            mirrorDependency.fields.push(mirrorDependencyField);
                        }
                    }
                    // The source field is a user field
                    else if (
                        referencedFieldOnTemplate.type === FieldTypes.User
                    ) {
                        this.logger.log(
                            `Field ${matchParts.groups?.["reference"]} found on datalist ${fieldTemplate.datalistID} and is an user field`
                        );
                        const mirrorDependencyField =
                            new UserMirrorDependencyOption();
                        mirrorDependencyField.fieldName =
                            referencedFieldOnTemplate.systemName;
                        mirrorDependencyField.fieldID =
                            referencedFieldOnTemplate.fieldID!;
                        mirrorDependencyField.mirrorType =
                            MirrorOptionType.User;
                        mirrorDependencyField.userPropertyName =
                            matchParts.groups?.["systemName"]; // TODO: UserData Object
                        mirrorDependencyField.mirrorLabel = innerMatch;
                        mirrorDependency.fields.push(mirrorDependencyField);
                    }
                }
                // Parent reference
                else if (
                    matchParts?.groups?.["reference"].toLowerCase() ===
                    MirrorFormulaConstants.parent
                ) {
                    const referenceSystemName =
                        matchParts?.groups?.["systemName"];
                    // Go over each parent dependency
                    referencedDatalist?.parentDependencies.forEach(
                        (parentNode: Node<DatalistTemplateDTO>) => {
                            if (
                                matchParts?.groups &&
                                !parentNode.fieldMap.has(referenceSystemName)
                            ) {
                                this.logger.error(
                                    `Field ${matchParts?.groups?.["systemName"]} not found on datalist ${parentNode.data.datalistID}`
                                );
                                throw new Error(
                                    `Field ${matchParts?.groups?.["systemName"]} not found on datalist ${parentNode.data.datalistID}`
                                );
                            }
                            const mirrorDependencyField =
                                new ParentMirrorDependencyOption();
                            mirrorDependencyField.parentDatalistFieldSystemName =
                                referenceSystemName; // Mirror source field name
                            mirrorDependencyField.parentDatalistID =
                                parentNode.data.datalistID;
                            mirrorDependencyField.parentDatalistSystemName =
                                parentNode.data.systemName;
                            mirrorDependencyField.parentDatalistFieldID =
                                parentNode.fieldMap.get(
                                    referenceSystemName
                                )!.fieldID!;
                            mirrorDependencyField.mirrorLabel = innerMatch;
                            mirrorDependency.fields.push(mirrorDependencyField);
                        }
                    );
                }
            }
            // Reference not found. Simple mirror
            else if (matchParts?.groups?.["systemName"] != null) {
                const mirrorDependencyField =
                    new RegularMirrorDependencyOption();
                mirrorDependencyField.fieldName =
                    matchParts?.groups?.["systemName"];
                // TODO: Clean this up
                mirrorDependencyField.fieldID = graph
                    .getNodeByID(fieldTemplate.datalistID)
                    .fieldMap.get(matchParts?.groups?.["systemName"])!.fieldID!;
                mirrorDependencyField.mirrorType = MirrorOptionType.Regular;
                mirrorDependencyField.mirrorLabel = innerMatch;
                mirrorDependency.fields.push(mirrorDependencyField);
            } else {
                // TODO: COAELSCE
            }
        });
        (fieldTemplate as MirrorFieldTemplateDTOArray).valueDependency =
            mirrorDependency;
    }

    /**
     * Transforms the given field template object into a FieldTemplateDTO object.
     *
     * @param fieldTemplate - The field template object to transform.
     * @returns The transformed FieldTemplateDTO object.
     */
    transformDataToFieldTemplateDTO(
        fieldTemplate: any,
        graph: Graph<DatalistTemplateDTO>
    ): FieldTemplateDTO {
        // Initialize field dependency object. This will be used to store the field dependency information.
        const fieldDependency = new FieldDependencyDTO();

        // Go over each property in the field template object
        for (const fieldProp in fieldTemplate) {
            // Redundant check
            if (
                Object.prototype.hasOwnProperty.call(fieldTemplate, fieldProp)
            ) {
                // Camel case conversion
                const camelCaseFieldProp =
                    fieldProp[0].toLowerCase() + fieldProp.slice(1);
                //#region Field Dependencies
                if (camelCaseFieldProp === "allDependenciesMustPass") {
                    fieldDependency.allDependenciesMustPass =
                        fieldTemplate[fieldProp];
                    delete fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "displayOption" ||
                    camelCaseFieldProp === "Displayoption"
                ) {
                    // enum conversion
                    fieldDependency.displayOption = fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "items") {
                    // Transform per field type
                    this.transformItemsToFieldTemplateDTOOptions(fieldTemplate);
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "dependencies") {
                    // List of dependencies
                    const dependencies: FieldDependencyOptionDTO[] =
                        this.transformDependenciesToFieldDependencyOptionDTO(
                            fieldTemplate
                        );
                    fieldDependency.dependencies = dependencies;
                }
                //#endregion
                //#region Validations
                else if (camelCaseFieldProp === "validations") {
                    const validators: FieldValidatorDTO[] =
                        this.transformValidationsToFieldValidatorDTO(
                            fieldTemplate
                        );

                    fieldTemplate.validators = validators;
                    delete fieldTemplate[fieldProp];
                }
                //#endregion
                //#region Field Options
                else if (camelCaseFieldProp === "fieldOptions") {
                    this.transformFieldOptions(fieldTemplate);
                }
                //#endregion
                else if (camelCaseFieldProp === "label") {
                    fieldTemplate.label = fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "addType" &&
                    fieldTemplate[fieldProp]
                ) {
                    fieldTemplate.type = this.transformFieldType(fieldTemplate);
                    delete fieldTemplate["addType"];
                } else if (
                    camelCaseFieldProp === "editType" &&
                    fieldTemplate[fieldProp]
                ) {
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "viewType") {
                    delete fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "defaultValue" &&
                    fieldTemplate[fieldProp] != null &&
                    fieldTemplate[fieldProp].match(
                        /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/g
                    ) != null
                ) {
                    graph
                        .getNodeByID(fieldTemplate.datalistID)
                        ?.mirrorFields.add(fieldTemplate.systemName);
                } else if (
                    camelCaseFieldProp === "defaultValue" &&
                    fieldTemplate[fieldProp] != null
                ) {
                    fieldTemplate.defaultValue = fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "adminOnlyInd") {
                    fieldTemplate.adminOnly =
                        this.booleanConverter.convertToBoolean(
                            fieldTemplate[fieldProp]
                        );
                    delete fieldTemplate[fieldProp];
                }
                // Rest of the properties
                if (
                    camelCaseFieldProp != fieldProp &&
                    fieldTemplate[fieldProp]
                ) {
                    fieldTemplate[camelCaseFieldProp] =
                        fieldTemplate[fieldProp];
                    delete fieldTemplate[fieldProp];
                }
            }
        }
        delete fieldTemplate["dependencies"];
        delete fieldTemplate["allDependenciesMustPass"];
        delete fieldTemplate["displayOption"];
        delete fieldTemplate["DisplayOption"];

        // Only add if there are dependencies defined on the field template
        if (fieldDependency?.dependencies?.length > 0) {
            fieldTemplate.visibilityDependency = fieldDependency;
        }

        fieldTemplate.fieldID = fieldTemplate.fieldId;
        delete fieldTemplate["fieldId"];
        return fieldTemplate;
    }

    transformValidationsToFieldValidatorDTO(
        fieldTemplate: any
    ): FieldValidatorDTO[] {
        return fieldTemplate["validations"].map((validator: any) => {
            const fieldValidator = new FieldValidatorDTO();
            for (const validatorProp in validator) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        validator,
                        validatorProp
                    )
                ) {
                    const camelCaseValidatorProp =
                        validatorProp[0].toLowerCase() + validatorProp.slice(1);
                    if (camelCaseValidatorProp === "regularExpression") {
                        fieldValidator.validationValue =
                            validator[validatorProp];
                    } else if (camelCaseValidatorProp === "errorMessage") {
                        fieldValidator.validationMessage =
                            validator[validatorProp];
                        if (validator[validatorProp].includes("Exceeds")) {
                            // Long string max. Need some better way to determine this
                            fieldValidator.validationType =
                                FieldValidators.LongStringMax;
                        } else {
                            fieldValidator.validationType =
                                FieldValidators.Regex;
                        }
                    }
                    if (camelCaseValidatorProp != validatorProp) {
                        validator[camelCaseValidatorProp] =
                            validator[validatorProp];
                        delete validator[validatorProp];
                    }
                }
            }
            return fieldValidator;
        });
    }

    getEnumValue<T extends object>(
        enumType: T,
        value: T[keyof T]
    ): T[keyof T] | undefined {
        const enumValues = Object.values(enumType) as T[keyof T][];
        return enumValues.includes(value) ? value : undefined;
    }

    transformOptionToDefaultSearchConfiguration(
        fieldTemplate: any,
        propertyName: string,
        optionValue: any
    ) {
        if (!fieldTemplate.defaultSearchConfiguration) {
            fieldTemplate.defaultSearchConfiguration =
                new FieldSearchConfiguration();
        }

        fieldTemplate.defaultSearchConfiguration[propertyName] = optionValue;
    }

    transformOptionToAgeProperty(
        fieldTemplate: any,
        ageProperty: string,
        optionValue: any
    ) {
        if (!fieldTemplate.ageProperties) {
            fieldTemplate.ageProperties = {};
        }

        fieldTemplate.ageProperties[ageProperty] =
            this.booleanConverter.convertToBoolean(optionValue);
    }
    transformFieldOptions(fieldTemplate: any) {
        const conditionallyMandatory = new ConditionallyMandatory();

        // Field Options
        fieldTemplate["fieldOptions"].forEach((fieldOption: any) => {
            const optionName = fieldOption["Key"];
            const optionValue = fieldOption["Value"];
            // Update option name to remove spaces and case correct.
            const modifiedOptionName = optionName
                .split(" ")
                .map((word: string, index: number) => {
                    // Lowercase the first word
                    if (index === 0) {
                        return word.toLowerCase();
                    }

                    // Capitalize the first letter of the rest of the words
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join("");

            switch (modifiedOptionName) {
                //#region Conditionally Mandatory
                case "conditionallyMandatory": // Create conditionally mandatory object
                    break;
                case "mandatedByField":
                    conditionallyMandatory.mandatedByField =
                        fieldOption["Value"];
                    break;
                case "mandatedByValue":
                    conditionallyMandatory.mandatedByValue =
                        fieldOption["Value"];
                    break;
                case "mandatedIfFieldHasValue":
                    conditionallyMandatory.mandatedIfFieldHasValue =
                        this.booleanConverter.convertToBoolean(
                            fieldOption["Value"]
                        );
                    break;
                case "mandatedBySystemName":
                    conditionallyMandatory.mandatedBySystemName =
                        fieldOption["Value"];
                    break;
                //#endregion
                //#region Boolean Options
                case "showAge":
                case "allowUpdates":
                case "displayAsMatrix":
                case "hidden":
                case "rootOfParent":
                case "hideLabel":
                case "cloneable":
                case "restrictOnPDF":
                case "disableCameraRoll":
                case "disableDrawingModal":
                case "disableFileAttachment":
                case "disableMicrophoneModal":
                case "disableSignatureModal":
                case "displayInitialOptions":
                case "hide360":
                case "hideAdd":
                case "hideSearch":
                case "recursiveSubscription":
                case "enableRange":
                case "enableSharing":
                case "deleteOnHide":
                case "preventEmbeddedAdd":
                case "preventEmbeddedEdit":
                case "preventEmbeddedDelete":
                case "defaultToNull":
                case "allowBulkEdit":
                case "summable":
                case "limitOptionsHeight":
                    fieldTemplate[modifiedOptionName] =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                //#endregion
                //#region Number Options
                case "indentLevel":
                case "fieldRestrictions":
                case "embeddedDocumentList":
                case "embeddedDocumentSource":
                case "minimumValue":
                case "maximumValue":
                    fieldTemplate[modifiedOptionName] =
                        Number.parseInt(optionValue);
                    break;
                //#endregion
                //#region String Options
                case "defaultSearchType":
                case "defaultSearchValue":
                    this.transformOptionToDefaultSearchConfiguration(
                        fieldTemplate,
                        modifiedOptionName,
                        optionValue
                    );
                    break;
                case "applyOnQuickWorkspaceSearch":
                case "requiresSearchBeforeAdd":
                case "searchable":
                    this.transformOptionToDefaultSearchConfiguration(
                        fieldTemplate,
                        modifiedOptionName,
                        this.booleanConverter.convertToBoolean(optionValue)
                    );
                    break;
                //#endregion
                case "simpleLongString":
                    fieldTemplate.isSimpleLongString =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "maxSize":
                    if (fieldTemplate.type === FieldTypes.Attachment) {
                        fieldTemplate.maxSize = Number.parseInt(optionValue);
                    } else {
                        fieldTemplate.maxLength = Number.parseInt(optionValue);
                    }
                    break;
                case "maskValue":
                    fieldTemplate.masked =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "enableAsCodeTable?":
                    fieldTemplate.enableAsCodeTable =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "ableToSelectMultipleValues":
                    fieldTemplate.multiSelect =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "displayAsRadioButtons":
                    fieldTemplate.displayAsRadio =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "hideToolbar":
                    fieldTemplate.hidePdfToolbar =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "includeInPDF":
                    fieldTemplate.includeInPdf =
                        this.booleanConverter.convertToBoolean(optionValue);
                    break;
                case "restrictedByField":
                    fieldTemplate.restrictedByFieldSystemName = optionValue;
                    break;
                case "defaultSoundsLike":
                    fieldTemplate.defaultSoundsLike = this.getEnumValue(
                        SoundsLikeValues,
                        optionValue
                    );
                    break;
                case "displayYears":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "years",
                        optionValue
                    );
                    break;
                case "displayMonths":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "months",
                        optionValue
                    );
                    break;
                case "displayDays":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "days",
                        optionValue
                    );
                    break;
                case "columnWidth":
                    fieldTemplate.columnWidth =
                        this.transformColumnWidthFieldOptionToColumnWidth(
                            modifiedOptionName,
                            optionValue
                        );
                    break;
                case "fieldMapping":
                    fieldTemplate.pdfFieldMapping = optionValue;
                    break;
                case "minEmbeddedInstances":
                    fieldTemplate.minEmbeddedCount =
                        Number.parseInt(optionValue);
                    break;
                case "maxEmbeddedInstances":
                    fieldTemplate.maxEmbeddedCount =
                        Number.parseInt(optionValue);
                    break;
                case "numberOfDecimalPlaces":
                    fieldTemplate.decimalPlaces = Number.parseInt(optionValue);
                    break;
                default:
                    fieldTemplate[modifiedOptionName] = optionValue;
                    break;
            }
        });

        delete fieldTemplate["fieldOptions"];
        if (Object.keys(conditionallyMandatory).length > 0) {
            fieldTemplate["conditionallyMandatory"] = conditionallyMandatory;
        }
    }

    transformColumnWidthFieldOptionToColumnWidth(
        key: string,
        value?: string
    ): ColumnWidthDisplayOptions | undefined {
        if (key !== "columnWidth") {
            this.logger.warn("Wrong key for column width option");
            return;
        }

        switch (value) {
            case "Single":
                return ColumnWidthDisplayOptions.ColumnWidthSingle;
            case "Double":
                return ColumnWidthDisplayOptions.ColumnWidthDouble;
            case "Full":
                return ColumnWidthDisplayOptions.ColumnWidthFull;
            default:
                this.logger.warn(
                    "Column width not found. Defaulting to single"
                );
                return ColumnWidthDisplayOptions.ColumnWidthSingle;
        }
    }

    transformDependenciesToFieldDependencyOptionDTO(fieldTemplate: any) {
        const dependencies: FieldDependencyOptionDTO[] = [];
        // Go over each dependency
        fieldTemplate["dependencies"]?.map((dependency: any) => {
            const fieldDependencyOption = new FieldDependencyOptionDTO();
            // Go over each dependency property
            for (const dependencyProp in dependency) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        dependency,
                        dependencyProp
                    )
                ) {
                    const camelCaseDependencyProp =
                        dependencyProp[0].toLowerCase() +
                        dependencyProp.slice(1);
                    if (camelCaseDependencyProp === "dependentFieldID") {
                        // enum conversion
                        fieldDependencyOption.dependentFieldID =
                            dependency[dependencyProp];
                    } else if (
                        camelCaseDependencyProp === "dependentOnFieldID"
                    ) {
                        fieldDependencyOption.dependentOnFieldID =
                            dependency[dependencyProp];
                    } else if (
                        camelCaseDependencyProp === "fieldDependencyID"
                    ) {
                        fieldDependencyOption.fieldDependencyID =
                            dependency[dependencyProp];
                    } else if (camelCaseDependencyProp === "value") {
                        fieldDependencyOption.value =
                            dependency[dependencyProp];
                    } else if (camelCaseDependencyProp === "type") {
                        // Will this throw an error if not found? This should an exception?
                        fieldDependencyOption.type =
                            FieldDependencyTypes[
                                dependency[
                                    dependencyProp
                                ] as keyof typeof FieldDependencyTypes
                            ];
                    }

                    if (camelCaseDependencyProp != dependencyProp) {
                        dependency[camelCaseDependencyProp] =
                            dependency[dependencyProp];
                        delete dependency[dependencyProp];
                    }
                }
            }

            dependencies.push(fieldDependencyOption);
        });
        return dependencies;
    }

    transformItemsToFieldTemplateDTOOptions(fieldTemplate: any): any {
        const fieldTemplateItems = fieldTemplate.items;
        switch (fieldTemplate.type) {
            // This is for TODO, DataMover, etc. Not supported yet
            case FieldTypes.Datalist:
                break;
            case FieldTypes.User:
                break;
            case FieldTypes.UserRoles:
                fieldTemplate.organizationRoles = new Map<number, string>();
                fieldTemplateItems.forEach((item: any) => {
                    fieldTemplate.organizationRoles.set(
                        item.value,
                        item.displayValue
                    );
                });
                break;
            case FieldTypes.CascadingDynamicDropdown:
            case FieldTypes.DynamicDropdown:
                fieldTemplate.source =
                    fieldTemplateItems[0].source ?? undefined;
                fieldTemplate.root = fieldTemplateItems[0].root ?? undefined;
                break;
            case FieldTypes.CascadingDropdown: {
                const cascadingDropdownValues: CascadingDropdownFieldValue[] =
                    [];
                fieldTemplateItems.forEach((item: any) => {
                    // Empty object check because of placeholder being sent by mCase
                    if (Object.keys(item).length === 0) return;

                    const dropdownValue = new CascadingDropdownFieldValue();
                    if (Object.prototype.hasOwnProperty.call(item, "parents")) {
                        dropdownValue.parent = item.parents ?? []; // This is an array. Length check?
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "FieldValueID"
                        )
                    ) {
                        dropdownValue.fieldValueID = item.FieldValueID;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "value")) {
                        dropdownValue.value = item.value;
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "displayValue"
                        )
                    ) {
                        dropdownValue.displayValue = item.displayValue;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "scores")) {
                        dropdownValue.scores = new DropdownScoreValue();
                        dropdownValue.scores.score1Value =
                            item.scores.score1value;
                        dropdownValue.scores.score2Value =
                            item.scores.score2value;
                        dropdownValue.scores.score3Value =
                            item.scores.score3value;
                        dropdownValue.scores.score4Value =
                            item.scores.score4value;
                        dropdownValue.scores.score5Value =
                            item.scores.score5value;
                        dropdownValue.scores.score6Value =
                            item.scores.score6value;
                        dropdownValue.scores.backgroundColor =
                            item.scores.BackgroundColor;
                        dropdownValue.scores.color = item.scores.Color;
                        dropdownValue.scores.isDisabled =
                            item.scores.IsDisabled;
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "BackgroundColor"
                        )
                    ) {
                        dropdownValue.backgroundColor = item.BackgroundColor;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "Color")) {
                        dropdownValue.color = item.Color;
                    }
                    cascadingDropdownValues.push(dropdownValue);
                });
                // TODO: Define concrete relationship between fieldTemplate and DropdownFieldValue
                fieldTemplate.values = cascadingDropdownValues;
                break;
            }
            case FieldTypes.Dropdown: {
                const dropdownValues: DropdownFieldValue[] = [];
                fieldTemplateItems.forEach((item: any) => {
                    const dropdownValue = new DropdownFieldValue();
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "FieldValueID"
                        )
                    ) {
                        dropdownValue.fieldValueID = item.FieldValueID;
                    }

                    if (Object.prototype.hasOwnProperty.call(item, "value")) {
                        dropdownValue.value = item.value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "displayValue"
                        )
                    ) {
                        dropdownValue.displayValue = item["displayValue"];
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "score1value"
                        )
                    ) {
                        dropdownValue.scores.score1Value = item.score1value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "score2value"
                        )
                    ) {
                        dropdownValue.scores.score2Value = item.score2value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "score3value"
                        )
                    ) {
                        dropdownValue.scores.score3Value = item.score3value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "score4value"
                        )
                    ) {
                        dropdownValue.scores.score4Value = item.score4value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "score5value"
                        )
                    ) {
                        dropdownValue.scores.score5Value = item.score5value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "score6value"
                        )
                    ) {
                        dropdownValue.scores.score6Value = item.score6value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "BackgroundColor"
                        )
                    ) {
                        dropdownValue.backgroundColor = item.BackgroundColor;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(item, "IsDisabled")
                    ) {
                        dropdownValue.scores.isDisabled = item.IsDisabled;
                    }

                    if (Object.prototype.hasOwnProperty.call(item, "Color")) {
                        dropdownValue.color = item.Color;
                    }
                    dropdownValues.push(dropdownValue);
                });
                fieldTemplate.values = dropdownValues;
                break;
            }
            case FieldTypes.Boolean:
                if (!fieldTemplateItems[0]) break;

                fieldTemplate.score1Value = fieldTemplateItems[0].score1value;
                fieldTemplate.score2Value = fieldTemplateItems[0].score2value;
                fieldTemplate.score3Value = fieldTemplateItems[0].score3value;
                fieldTemplate.score4Value = fieldTemplateItems[0].score4value;
                fieldTemplate.score5Value = fieldTemplateItems[0].score5value;
                fieldTemplate.score6Value = fieldTemplateItems[0].score6value;
                break;
            case FieldTypes.CalculatedField:
            case FieldTypes.EmbeddedList:
            case FieldTypes.EmbeddedDocument:
            case FieldTypes.Number:
            case FieldTypes.LongString:
            default:
                break;
        }
        // Regex possibly
    }

    transformTemplates(templates: any): any {
        const graph = new Graph<DatalistTemplateDTO>([]);

        if (templates == null || templates.length === 0) {
            this.logger.info("No templates found");
            return graph;
        }

        if (typeof templates == "object" && !Array.isArray(templates)) {
            templates = [templates];
        }
        // Get template DTOs
        // const transformedTemplates = templates.map((template: any) => {
        //     return transformResponseToDatalistDTO(template, graph);
        // });

        const transformedTemplates: DatalistTemplateDTO[] = [];

        templates.forEach((template: any) => {
            try {
                const transformedTemplate = this.transformResponseToDatalistDTO(
                    template,
                    graph
                );
                transformedTemplates.push(transformedTemplate);
            } catch (error) {
                this.logger.error(
                    `Error transforming template ${template.datalistID}`,
                    error
                );
            }
        });
        this.logger.debug(
            `Transformed ${transformedTemplates.length} templates`
        );
        // Add relationships
        transformedTemplates.forEach((template: DatalistTemplateDTO) => {
            // Relationships
            template.children.forEach(
                (child: ChildDatalistRelationshipTemplateDTO) => {
                    if (
                        !graph.getNodeByID(template.datalistID) ||
                        !graph.getNodeByID(child.childListID)
                    ) {
                        return;
                    }
                    graph.addEdge(
                        graph.getNodeByID(template.datalistID),
                        graph.getNodeByID(child.childListID)
                    );
                    graph
                        .getNodeByID(child.childListID)
                        ?.addParentDependency(
                            graph.getNodeByID(template.datalistID)
                        );
                }
            );
        });

        this.logger.debug(`Templates transformed and relationships added`);
        graph.nodes.forEach((node: Node<DatalistTemplateDTO>) => {
            const mirrorFields = node.mirrorFields;
            mirrorFields.forEach((fieldSystemName: string) => {
                const mirrorField = node.fieldMap.get(fieldSystemName);

                if (mirrorField) {
                    try {
                        this.generateMirrorDependencyForFieldTemplate(
                            mirrorField,
                            graph
                        );
                    } catch (error) {
                        this.logger.error(
                            `Error generating mirror dependency for field ${fieldSystemName} on datalist ${node.data.datalistID}`
                        );
                    }
                }
            });

            // These are DDD fields
            const dynamicFields = node.dynamicFields;
            dynamicFields.forEach((fieldSystemName: string) => {
                const dynamicField = node.fieldMap.get(fieldSystemName);

                if (dynamicField) {
                    //TODO: This logic will need to be more refined because of root and source complexity
                    const dynamicFieldSource = (
                        dynamicField as DynamicDropdownFieldTemplateDTO
                    ).source;

                    if (dynamicFieldSource) {
                        const parent = graph.getNodeByID(dynamicFieldSource);
                        // DDD datalist will be a parent to the current datalist
                        if (parent) {
                            graph.addEdge(parent, node);
                        } else {
                            //TODO: Is this a critical error?
                            this.logger.error(
                                `Parent not found for DDD field ${dynamicField.systemName} with source ${dynamicFieldSource}`
                            );
                        }
                    }
                }
            });
        });
        this.logger.debug(`Graph created with ${graph.nodes.size} nodes`);
        return graph;
    }

    transformDataToClientSideEventTemplateDTO(
        clientSideEventTemplate: any
    ): ClientSideEventTemplateDTO {
        const newClientSideEventTemplate = new ClientSideEventTemplateDTO();
        for (const prop in clientSideEventTemplate) {
            if (
                Object.prototype.hasOwnProperty.call(
                    clientSideEventTemplate,
                    prop
                )
            ) {
                const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp != prop) {
                    clientSideEventTemplate[camelCaseProp] =
                        clientSideEventTemplate[prop];
                    delete clientSideEventTemplate[prop];
                }

                if (camelCaseProp === "clientWorkFlowOptions") {
                    const clientWorkflowOptions =
                        new ClientWorkflowOptionsDTO();
                    for (const clientWorkflowOptionProp in clientSideEventTemplate[
                        camelCaseProp
                    ]) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                clientSideEventTemplate[camelCaseProp],
                                clientWorkflowOptionProp
                            )
                        ) {
                            const camelCaseClientWorkflowOptionProp =
                                clientWorkflowOptionProp[0].toLowerCase() +
                                clientWorkflowOptionProp.slice(1);
                            if (
                                camelCaseClientWorkflowOptionProp == "triggers"
                            ) {
                                clientWorkflowOptions.triggers =
                                    clientSideEventTemplate[camelCaseProp][
                                        clientWorkflowOptionProp
                                    ];
                            } else if (
                                camelCaseClientWorkflowOptionProp ==
                                "valueChangeFields"
                            ) {
                                if (
                                    clientSideEventTemplate[camelCaseProp][
                                        clientWorkflowOptionProp
                                    ]
                                ) {
                                    clientWorkflowOptions.valueChangeFields =
                                        clientSideEventTemplate[camelCaseProp][
                                            clientWorkflowOptionProp
                                        ];
                                }
                            }
                        }
                    }

                    delete clientSideEventTemplate[camelCaseProp];
                    clientSideEventTemplate.clientWorkflowOptions =
                        clientWorkflowOptions;
                } else if (camelCaseProp === "clientWorkFlowID") {
                    clientSideEventTemplate.clientWorkflowID =
                        clientSideEventTemplate[camelCaseProp];
                    delete clientSideEventTemplate[camelCaseProp];
                }
            }
        }

        Object.assign(newClientSideEventTemplate, clientSideEventTemplate);
        return newClientSideEventTemplate;
    }

    transformDataToSearchableDescendantListsTemplate(
        searchableDescendantListsTemplate: any
    ): SearchableDescendantListsTemplateDTO {
        const newSearchableDescendantListsTemplate =
            new SearchableDescendantListsTemplateDTO();
        for (const prop in searchableDescendantListsTemplate) {
            if (
                Object.prototype.hasOwnProperty.call(
                    searchableDescendantListsTemplate,
                    prop
                )
            ) {
                const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp != prop) {
                    searchableDescendantListsTemplate[camelCaseProp] =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                }

                if (camelCaseProp === "dataListID") {
                    newSearchableDescendantListsTemplate.datalistID =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                } else if (camelCaseProp === "dataListLabel") {
                    newSearchableDescendantListsTemplate.datalistLabel =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                } else if (camelCaseProp === "template") {
                    // This might be quite expensive. Need to check if this is necessary
                    // newSearchableDescendantListsTemplate.template =
                    //     transformResponseToDatalistDTO(
                    //         searchableDescendantListsTemplate[prop]
                    //     );
                    // delete searchableDescendantListsTemplate[prop];
                }
            }
        }

        Object.assign(
            newSearchableDescendantListsTemplate,
            searchableDescendantListsTemplate
        );
        return newSearchableDescendantListsTemplate;
    }

    iconCharacterMap = new Map([
        ["iconid", "id"],
        ["backgroundiconcolor", "backgroundColor"],
        ["iconcategory", "category"],
        ["iconcolor", "color"],
        ["iconname", "name"],
        ["upgraded", "upgraded"],
    ]);

    transformToIconTemplateDTO(optionTemplate: any): IconTemplateDTO {
        const iconCharacter = optionTemplate.IconCharacter;
        const newIconTemplate = new IconTemplateDTO();
        const parseIconCharacter = JSON.parse(iconCharacter);
        for (const prop in parseIconCharacter) {
            if (this.iconCharacterMap.has(prop.toLowerCase())) {
                const characterValue = this.iconCharacterMap.get(
                    prop.toLowerCase()
                );
                if (characterValue == "category") {
                    parseIconCharacter[characterValue] = decodeURIComponent(
                        parseIconCharacter[prop]
                    );
                } else if (characterValue) {
                    parseIconCharacter[characterValue] =
                        parseIconCharacter[prop];
                }
                delete parseIconCharacter[prop];
            }
        }
        Object.assign(newIconTemplate, parseIconCharacter);

        if (optionTemplate.image) {
            newIconTemplate.image = optionTemplate.image;
        }
        return newIconTemplate;
    }

    optionTemplateMap = new Map([
        ["datalisttype", "datalistType"],
        ["defaultviewtype", "defaultViewType"],
        ["maxnumberofcolumns", "maxNumberOfColumns"],
        ["topleveldisplay", "isTopLevelDisplay"],
        ["displaypreviousandnextbuttons", "diplayPreviousAndNextButtons"],
        [
            "autosavewithnextandpreviousbuttons",
            "autoSaveWithNextAndPreviousButtons",
        ],
        ["hideemptychildren", "hideEmptyChildren"],
        ["hidewhenreadonly", "hideWhenReadOnly"],
        ["preventinlineedit", "preventInlineEdit"],
        ["preventinlineadd", "preventInlineAdd"],
        ["combinedgridoptiontemplate", "combinedGridOptionTemplate"],
        ["iconcharacter", "iconCharacter"],
        ["cloneable", "cloneable"],
        ["disallowaddsfromtoplevel", "disallowAddsFromTopLevel"],
        [
            "requiresindividualmobilesubscription",
            "requiresIndividualMobileSubscription",
        ],
        ["enablesharing", "enableSharing"],
        ["enablesoundslikeonmobile", "enableSoundsLikeOnMobile"],
        ["asearchisrequiredtoviewrecords", "searchIsRequiredToViewRecords"],
        ["recordlabelformat", "recordLabelFormat"],
        ["recordheadercolumns", "recordHeaderColumns"],
    ]);
    transformDataToOptionTemplateDTO(optionTemplate: any): OptionTemplateDTO {
        const newOptionTemplate = new OptionTemplateDTO();
        // Option Template
        for (const prop in optionTemplate) {
            // Lowercase and remove hyphens
            const camelCaseProp = prop.toLowerCase().replace(/-/g, "");
            if (this.optionTemplateMap.has(camelCaseProp)) {
                const key = this.optionTemplateMap.get(camelCaseProp)!;
                const value = optionTemplate[prop];
                if (camelCaseProp === "combinedgridoptiontemplate") {
                    // This might be quite expensive. Need to check if this is necessary
                    const combinedGridOptionTemplate =
                        new CombinedGridOptionTemplateDTO();

                    for (const combinedGridProp in optionTemplate[prop]) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                optionTemplate[prop],
                                combinedGridProp
                            )
                        ) {
                            const camelCaseCombinedGridProp =
                                combinedGridProp[0].toLowerCase() +
                                combinedGridProp.slice(1);
                            if (camelCaseCombinedGridProp != combinedGridProp) {
                                optionTemplate[prop][
                                    camelCaseCombinedGridProp
                                ] = optionTemplate[prop][combinedGridProp];
                                delete optionTemplate[prop][combinedGridProp];
                            }
                        }
                    }
                    //TODO: Check if combined grid templates are being created properly
                    Object.assign(
                        combinedGridOptionTemplate,
                        optionTemplate[prop]
                    );

                    optionTemplate[key] = combinedGridOptionTemplate;
                }
                // Icon Character
                else if (camelCaseProp === "iconcharacter") {
                    // image => iconCharacter
                    optionTemplate[key] =
                        this.transformToIconTemplateDTO(optionTemplate);
                }
                // Non boolean values
                else if (
                    camelCaseProp === "datalisttype" ||
                    camelCaseProp === "defaultviewtype" ||
                    camelCaseProp === "recordheadercolumns" ||
                    camelCaseProp === "recordlabelformat"
                ) {
                    optionTemplate[key] = value;
                } else if (camelCaseProp === "maxnumberofcolumns") {
                    optionTemplate[key] = Number.parseInt(value);
                }
                // Boolean values
                else {
                    optionTemplate[key] =
                        this.booleanConverter.convertToBoolean(value);
                }
            } else {
                this.logger.warn(
                    `Option template property ${camelCaseProp} not found`
                );
            }

            delete optionTemplate[prop];
        }
        // Assign back to template object
        // TODO: Separate transformation for option template especially combined grid
        Object.assign(newOptionTemplate, optionTemplate);

        return newOptionTemplate;
    }
    //#endregion
}
