import { Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { DateTimeFormatDTO } from "../models/dto/date-time-format-settings.dto";
import { EmailSettingsDTO } from "../models/dto/email-settings.dto";
import { GeneralSettingsMobileDTO } from "../models/dto/general-settings-mobile.dto";
import { MobileImagesDTO } from "../models/dto/mobile-images.dto";
import { MobileSettingsDTO } from "../models/dto/mobile-settings.dto";
import { SystemSettingsDTO } from "../models/dto/system-settings.dto";
import { SystemSettingsJSON } from "../models/response/system-settings.response";

@Injectable({
    providedIn: "root",
})
/**
 * Mapper class for mapping between SystemSettingsJSON and SystemSettingsDTO objects.
 */
export class SystemSettingsJsonDtoMapper extends Mapper<
    SystemSettingsJSON,
    SystemSettingsDTO
> {
    /**
     * Maps the SystemSettingsJSON object to a SystemSettingsDTO object.
     * @param systemSettingsJSON - The SystemSettingsJSON object to be mapped.
     * @returns The mapped SystemSettingsDTO object.
     */
    override mapFrom(
        systemSettingsJSON: SystemSettingsJSON
    ): SystemSettingsDTO {
        const {
            emailSettingsModule,
            generalSettingsModule,
            mobileSettingsModule,
            mobileImages: mobileImagesJSON,
            dateFormatFrontEnd,
            dateTimeFormatFrontEnd,
            dateTimeFormatSencha,
            dateFormatSencha,
            organizationTimezone,
            ...otherConfig
        } = systemSettingsJSON;

        const emailSettings = new EmailSettingsDTO(emailSettingsModule);
        const generalSettings = new GeneralSettingsMobileDTO(
            generalSettingsModule
        );
        const mobileSettings = new MobileSettingsDTO(mobileSettingsModule);
        const mobileImages = mobileImagesJSON.map(
            (mobileImage) => new MobileImagesDTO(mobileImage)
        );
        const dateTimeFormat = new DateTimeFormatDTO({
            dateTimeFormatFrontEnd,
            dateFormatFrontEnd,
            dateTimeFormatSencha,
            dateFormatSencha,
            organizationTimezone,
        });

        return new SystemSettingsDTO(
            emailSettings,
            generalSettings,
            mobileSettings,
            mobileImages,
            dateTimeFormat,
            otherConfig.showRedactedFieldsInPdf,
            otherConfig.organizationId
        );
    }

    /**
     * Maps the SystemSettingsDTO object to a SystemSettingsJSON object.
     * @param systemSettingDTO - The SystemSettingsDTO object to be mapped.
     * @returns The mapped SystemSettingsJSON object.
     */
    override mapTo(systemSettingDTO: SystemSettingsDTO): SystemSettingsJSON {
        const {
            dateTimeFormatFrontEnd,
            dateFormatFrontEnd,
            dateTimeFormat: dateTimeFormatSencha,
            dateFormat: dateFormatSencha,
            organizationTimezone,
        } = systemSettingDTO.dateTimeFormat;
        return {
            emailSettingsModule: {
                ConfigureComplexityForPasswordForShareEmail:
                    systemSettingDTO.emailSettings
                        .configureComplexityForPasswordForShareEmail,
                ConfigureValidationMessageForPasswordComplexity:
                    systemSettingDTO.emailSettings
                        .configureValidationMessageForPasswordComplexity,
                ConfigureEmailRegexForShareEmail:
                    systemSettingDTO.emailSettings
                        .configureEmailRegexForShareEmail,
                ConfigureValidationMessageForEmailRegex:
                    systemSettingDTO.emailSettings
                        .configureValidationMessageForEmailRegex,
                MFAforSHARE: systemSettingDTO.emailSettings.mfaForShare,
            },
            generalSettingsModule: systemSettingDTO.generalSettings,
            mobileSettingsModule: systemSettingDTO.mobileSettings,
            showRedactedFieldsInPdf: systemSettingDTO.showRedactedFieldsInPdf,
            organizationId: systemSettingDTO.organizationId,
            mobileImages: systemSettingDTO.mobileImages,
            dateFormatFrontEnd,
            dateFormatSencha,
            dateTimeFormatFrontEnd,
            dateTimeFormatSencha,
            organizationTimezone,
        };
    }
}
