import { DropdownScoreValue } from "./drop-down-score-value.model";

export class DropdownFieldValue {
    fieldValueID: number = 0;
    value!: string;
    displayValue!: string;
    scores: DropdownScoreValue = new DropdownScoreValue();
    backgroundColor?: string;
    color?: string;
}
