import { DependencyDisplayOptions } from "../../../domain/enums/dependency-display-options.enum";
import { FieldDependencyOptionDTO } from "./field-dependency-option.dto";

/**
 * Represents a field dependency.
 */
export class FieldDependencyDTO {
    /**
     * The ID of the field dependency.
     */
    fieldDependencyID: number = 0;

    /**
     * Indicates whether all dependencies must pass for the field to be displayed.
     */
    allDependenciesMustPass: boolean = false;

    /**
     * The display option for the field dependency.
     */
    displayOption: DependencyDisplayOptions = DependencyDisplayOptions.Show;

    /**
     * The dependencies for the field.
     */
    dependencies: FieldDependencyOptionDTO[] = [];

    /**
     * Creates a new instance of the FieldDependencyDTO class.
     */
    constructor() {}
}
