import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { IMirrorDependencyOption } from "./IMirror-dependency-option";

export class ParentMirrorDependencyOption implements IMirrorDependencyOption {
    mirrorLabel!: string;
    fieldName: string = "";
    fieldID: number = 0;
    mirrorType: MirrorOptionType = MirrorOptionType.Parent;
    parentDatalistID: number = 0;
    parentDatalistSystemName: string = "";
    parentDatalistFieldSystemName: string = "";
    parentDatalistFieldID: number = 0;
}
