import {
    NgModule,
    isDevMode,
    provideExperimentalZonelessChangeDetection,
} from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RouterModule } from "@angular/router";
import {
    provideHttpClient,
    withInterceptors,
    withFetch,
} from "@angular/common/http";
import { authHeaderInterceptor } from "./core/auth/infrastructure/interceptors/auth-header.interceptor";
import { userInterceptor } from "./login/infrastructure/interceptors/user.interceptor";
import { systemSettingsInterceptor } from "./core/system-settings/infrastructure/interceptors/system-settings.interceptor";
import { templateInterceptor } from "./core/infrastructure/interceptors/template.interceptor";
import { chunkInterceptor } from "./core/loadProcess/infrastructure/interceptors/chunk.interceptor";
import { largeObjectInterceptor } from "./core/loadProcess/infrastructure/interceptors/large-object.interceptor";
import { mapOverlayInterceptor } from "./core/loadProcess/infrastructure/interceptors/map-overlay.interceptor";
import { serverTimeoutInterceptor } from "./core/server-timeout/infrastructure/interceptors/server-timeout.interceptor";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { notificationInterceptor } from "./inbox/infrastructure/interceptors/notification.interceptor";
import { SharedModule } from "./shared/shared.module";

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        AppRoutingModule,
        RouterModule,
        MatSnackBarModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
        SharedModule,
    ],
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideAnimationsAsync(),
        provideHttpClient(
            withFetch(),
            withInterceptors([
                systemSettingsInterceptor,
                authHeaderInterceptor,
                serverTimeoutInterceptor,
                // tokenInterceptor,
                templateInterceptor,
                mapOverlayInterceptor,
                userInterceptor,
                chunkInterceptor,
                largeObjectInterceptor,
                notificationInterceptor,
            ])
        ),
    ],
})
export class AppModule {}
