/**
 * Interceptor for handling chunked record data in HTTP requests.
 * This interceptor transforms the record chunks in the response body into RecordInstanceDTO objects.
 * It also logs the transformation time for each record chunk.
 */
import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { RecordInstanceDTO } from "../../../../record/data/models/recordInstance/recordInstance.dto";
import { FieldInstanceDTO } from "../../../../record/data/models/fieldInstances/field-instance.dto";

/**
 * The chunkInterceptor function is the main interceptor logic for handling chunked record data.
 * It transforms the record chunks in the response body into RecordInstanceDTO objects.
 * @param request The HTTP request being intercepted.
 * @param next The next HTTP handler in the interceptor chain.
 * @returns An Observable of the HTTP event.
 */
export const chunkInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    // Inject the logger service
    const logger = inject(LOGGER);

    // Define the mCase routes from the environment
    const mCaseRoutes = environment.routes.mCase.routes;

    // Define the approved routes for chunked record data
    const approvedRoutes = [mCaseRoutes.postChunkedRecordData.url];

    /**
     * Transforms an array of record chunks into an array of RecordInstanceDTO objects.
     * @param recordChunks The array of record chunks to transform.
     * @returns The transformed array of RecordInstanceDTO objects.
     */
    const transformRecordChunks = (recordChunks: any): RecordInstanceDTO[] => {
        const start = performance.now();

        // If recordChunks is null, empty, or undefined, return it as is
        if (
            recordChunks == null ||
            recordChunks == "" ||
            recordChunks == undefined
        ) {
            logger.debug("Record chunks are null, empty, or undefined");
            return recordChunks;
        }

        // Transform each record chunk into a RecordInstanceDTO object
        const transformedRecordChunks: RecordInstanceDTO[] = recordChunks
            .map((recordChunk: any) => {
                const recordInstanceArray: RecordInstanceDTO[] = [];
                transformRecordChunk(recordChunk, recordInstanceArray);

                return recordInstanceArray;
            })
            .reduce((acc: RecordInstanceDTO[], val: RecordInstanceDTO[]) => {
                return acc.concat(val);
            });

        const end = performance.now();
        logger.debug(
            `Transformed record chunks in ${end - start} milliseconds. Total record chunks transformed: ${transformedRecordChunks.length}`
        );
        return transformedRecordChunks;
    };

    /**
     * Transforms a single record chunk into a RecordInstanceDTO object.
     * @param recordChunk The record chunk to transform.
     * @returns The transformed RecordInstanceDTO object.
     */
    const transformRecordChunk = (
        recordChunk: any,
        recordInstanceArray: RecordInstanceDTO[]
    ): RecordInstanceDTO[] => {
        const start = performance.now();
        // If recordChunk is null, empty, or undefined, return it as is
        if (
            recordChunk == null ||
            recordChunk == "" ||
            recordChunk == undefined
        ) {
            return recordChunk;
        }

        // Transform the record chunk into a RecordInstanceDTO object
        const recordInstanceDTO: RecordInstanceDTO = Object.assign(
            new RecordInstanceDTO(),
            recordChunk
        );

        // Map specific properties from the record chunk to the RecordInstanceDTO object
        if (Object.hasOwnProperty.call(recordChunk, "Nonces")) {
            recordInstanceDTO.nonces = recordChunk["Nonces"];
        }
        // if (Object.hasOwnProperty.call(recordChunk, "fields")) {
        //     recordInstanceDTO.fieldInstanceDTOs = recordChunk["fields"];
        // }
        if (Object.hasOwnProperty.call(recordChunk, "listId")) {
            recordInstanceDTO.datalistID = recordChunk["listId"];
        }
        if (Object.hasOwnProperty.call(recordChunk, "id")) {
            recordInstanceDTO.recordInstanceID = recordChunk["id"];
        }
        if (Object.hasOwnProperty.call(recordChunk, "parentRecordID")) {
            recordInstanceDTO.parentRecordInstanceID =
                recordChunk["parentRecordID"];
        }

        if (Object.hasOwnProperty.call(recordChunk, "fields")) {
            const DTOs: FieldInstanceDTO[] = recordChunk["fields"].map(
                (fieldDTO: any) => {
                    const fieldInstanceDTO = new FieldInstanceDTO();
                    if (
                        Object.hasOwnProperty.call(fieldDTO, "fieldInstanceId")
                    ) {
                        fieldInstanceDTO.fieldInstanceID =
                            fieldDTO["fieldInstanceId"];
                    }
                    if (
                        Object.hasOwnProperty.call(fieldDTO, "recordInstanceId")
                    ) {
                        fieldInstanceDTO.recordInstanceID =
                            fieldDTO["recordInstanceId"];
                    }
                    if (Object.hasOwnProperty.call(fieldDTO, "fieldId")) {
                        fieldInstanceDTO.fieldID = fieldDTO["fieldId"];
                    }
                    if (Object.hasOwnProperty.call(fieldDTO, "value")) {
                        fieldInstanceDTO.value = fieldDTO["value"];
                    }
                    if (Object.hasOwnProperty.call(fieldDTO, "displayValue")) {
                        fieldInstanceDTO.displayValue =
                            fieldDTO["displayValue"];
                    }
                    if (Object.hasOwnProperty.call(fieldDTO, "fieldSysName")) {
                        fieldInstanceDTO.fieldSystemName =
                            fieldDTO["fieldSysName"];
                    }
                    if (Object.hasOwnProperty.call(fieldDTO, "dynamicRef")) {
                        fieldInstanceDTO.dynamicRef = fieldDTO["dynamicRef"];
                    }
                    if (Object.hasOwnProperty.call(fieldDTO, "Checksum")) {
                        fieldInstanceDTO.checksum = fieldDTO["Checksum"];
                    }
                    return fieldInstanceDTO;
                }
            );
            recordInstanceDTO.fieldInstanceDTOs = DTOs;
        }

        const end = performance.now();
        logger.debug(
            `Transformed record chunk in ${end - start} milliseconds with recordInstanceID ${recordInstanceDTO.recordInstanceID}`
        );

        if (Object.hasOwnProperty.call(recordChunk, "lists")) {
            recordChunk.lists.forEach((list: any) => {
                if (
                    list != null &&
                    list != "" &&
                    list != undefined &&
                    list.records != null &&
                    list.records != "" &&
                    list.records != undefined
                ) {
                    list.records.forEach((record: any) => {
                        transformRecordChunk(record, recordInstanceArray);
                    });
                }
            });
        }
        recordInstanceArray.push(recordInstanceDTO);
        return recordInstanceArray;
    };

    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !approvedRoutes.includes(response.url)
            ) {
                return response;
            }

            const start = performance.now();

            // Process all templates
            if (approvedRoutes.includes(response.url)) {
                response = response.clone({
                    body: transformRecordChunks(response.body),
                });
            }

            const end = performance.now();
            logger.debug(`Chunk Interceptor took ${end - start}ms`);
            return response;
        })
    );
};
