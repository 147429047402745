import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { MapOverlayDTO } from "../../data/model/mapOverlay/map-overlay.dto";
import { MapOverlayTypes } from "../../../domain/enums/map-overlay-types.enum";

// This response interceptor is used to intercept the token response.
export const mapOverlayInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const mCaseRoutes = environment.routes.mCase.routes;
    const approvedRoutes = [mCaseRoutes.getMapOverlays.url];
    const logger = inject(LOGGER);
    const transformMapOverlays = (mapOverlay: any): MapOverlayDTO[] => {
        const start = performance.now();
        if (!mapOverlay) {
            // return empty array
            return [];
        }

        const transformedMapOverlay: MapOverlayDTO[] = mapOverlay.map(
            (mapOverlay: any) => {
                // Transform map overlay here
                return transformMapOverlay(mapOverlay);
            }
        );
        const end = performance.now();
        logger.debug(
            `Transformed map overlays in ${end - start} milliseconds. Total map overlays transformed: ${transformedMapOverlay.length}`
        );
        return transformedMapOverlay;
    };

    const transformMapOverlay = (mapOverlay: any): MapOverlayDTO => {
        const mapOverlayDTO = new MapOverlayDTO();
        if (Object.prototype.hasOwnProperty.call(mapOverlay, "Data")) {
            mapOverlayDTO.data = mapOverlay["Data"];
        }
        if (Object.prototype.hasOwnProperty.call(mapOverlay, "Category")) {
            mapOverlayDTO.category = mapOverlay["Category"];
        }
        if (Object.prototype.hasOwnProperty.call(mapOverlay, "Type")) {
            const type: string = mapOverlay["Type"];
            mapOverlayDTO.type =
                MapOverlayTypes[type as keyof typeof MapOverlayTypes];
        }
        if (Object.prototype.hasOwnProperty.call(mapOverlay, "ID")) {
            mapOverlayDTO.mapOverlayID = mapOverlay["ID"];
        }
        if (Object.prototype.hasOwnProperty.call(mapOverlay, "Name")) {
            mapOverlayDTO.name = mapOverlay["Name"];
        }
        return mapOverlayDTO;
    };
    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            // TODO: Implement context token for caching.
            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !approvedRoutes.includes(response.url)
            ) {
                return response;
            }

            const start = performance.now();
            // Process all templates
            if (response.url.includes(mCaseRoutes.getMapOverlays.url)) {
                response = response.clone({
                    body: transformMapOverlays(response.body),
                });
            }
            const end = performance.now();
            logger.debug(
                `Map Overlay Interceptor took ${end - start} milliseconds`
            );
            return response;
        })
    );
};
