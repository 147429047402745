import { IScoreProperties } from "../../../record/core/base/fieldInterfaces/IScore-properties";

export class DropdownScoreValue implements IScoreProperties {
    score1Value: number = 0;
    score2Value: number = 0;
    score3Value: number = 0;
    score4Value: number = 0;
    score5Value: number = 0;
    score6Value: number = 0;
    backgroundColor?: string;
    color?: string;
    isDisabled: boolean = false;
}
