import { DatalistTemplateDTO } from "./datalist-template.dto";

/**
 * Represents a Searchable Descendant Lists Template DTO.
 */
export class SearchableDescendantListsTemplateDTO {
    /**
     * The ID of the datalist.
     */
    public datalistID: number = 0;

    /**
     * The label of the datalist.
     */
    public datalistLabel: string = "";

    /**
     * The template associated with the datalist.
     */
    public template!: DatalistTemplateDTO;

    /**
     * An array of searchable field IDs.
     */
    public searchableFieldIDs: number[] = [];
}
