import { FieldDependencyTypes } from "../../../domain/enums/field-dependency-types.enum";

/**
 * Represents a Field Dependency Option Data Transfer Object.
 */
export class FieldDependencyOptionDTO {
    /**
     * The ID of the field dependency.
     */
    fieldDependencyID: number = 0;

    /**
     * The ID of the dependent field. This could be removed since it is already in the FieldDependency class.
     */
    dependentFieldID: number = 0;

    /**
     * The ID of the field that the dependent field depends on.
     */
    dependentOnFieldID: number = 0;

    /**
     * The type of the field dependency.
     */
    type: FieldDependencyTypes = FieldDependencyTypes.Null;

    /**
     * The value of the field dependency.
     */
    value: string = "";
}
