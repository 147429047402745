import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ILogger } from "../../../logging/models/logger.model";
import { LOGGER } from "../../../logging/providers/logger.provider";

/**
 * Guarding routes based on user's login status if not redirecting them to login page
 */
export const authGuard: CanActivateFn = () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const logger: ILogger = inject(LOGGER);

    if (authService.isUserLoggedIn()) {
        logger.info("Auth guard - User is logged in, allowing access to route");
        return true;
    }

    logger.debug("Auth guard - User not logged in, redirecting to login");
    return router.createUrlTree(["/login"]);
};
