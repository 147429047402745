/**
 * This file contains the implementation of the `largeObjectInterceptor` HTTP interceptor.
 * The interceptor is used to transform large objects in the HTTP response body.
 * It intercepts the response and applies the transformation logic to the large objects.
 * The transformed objects are then returned in the response body.
 */
import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { LargeObjectDTO } from "../../data/model/largeObject/large-object.dto";

/**
 * The `largeObjectInterceptor` function is the implementation of the HTTP interceptor.
 * It intercepts the HTTP request and response, and applies the transformation logic to large objects in the response body.
 * The transformed objects are then returned in the response body.
 *
 * @param request - The HTTP request being intercepted.
 * @param next - The next HTTP handler in the interceptor chain.
 * @returns An Observable of the HTTP event.
 */
export const largeObjectInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    // Define the mCase routes and the approved routes for transformation
    const mCaseRoutes = environment.routes.mCase.routes;
    const approvedRoutes = [mCaseRoutes.getLargeObjectData.url];
    const logger = inject(LOGGER);

    /**
     * Transforms an array of large objects into an array of LargeObjectDTO.
     *
     * @param largeObjects - The array of large objects to transform.
     * @returns The transformed array of LargeObjectDTO.
     */
    const transformLargeObjects = (largeObjects: any): LargeObjectDTO[] => {
        const start = performance.now();
        if (
            largeObjects == null ||
            largeObjects == "" ||
            largeObjects == undefined
        ) {
            return largeObjects;
        }

        const transformedLargeObject: LargeObjectDTO[] = largeObjects.map(
            (largeObject: any) => {
                // Transform large object here
                return transformLargeObject(largeObject);
            }
        );
        const end = performance.now();
        logger.debug(
            `Transformed large objects in ${end - start} milliseconds. Total large objects transformed: ${transformedLargeObject.length}`
        );
        return transformedLargeObject;
    };

    /**
     * Transforms a single large object into a LargeObjectDTO.
     *
     * @param largeObject - The large object to transform.
     * @returns The transformed LargeObjectDTO.
     */
    const transformLargeObject = (largeObject: any): LargeObjectDTO => {
        const start = performance.now();
        if (
            largeObject == null ||
            largeObject == "" ||
            largeObject == undefined
        ) {
            return largeObject;
        }
        // Transform large object here
        const largeObjectDTO: LargeObjectDTO = Object.assign(
            new LargeObjectDTO(),
            largeObject
        );

        if (Object.hasOwnProperty.call(largeObject, "fieldId")) {
            largeObjectDTO.fieldInstanceID = largeObject["fieldId"];
        }
        if (Object.hasOwnProperty.call(largeObject, "RecordInstanceID")) {
            largeObjectDTO.recordInstanceID = largeObject["RecordInstanceID"];
        }
        const end = performance.now();
        logger.debug(
            `Transformed large object with fieldInstanceID ${largeObjectDTO.fieldInstanceID} in ${end - start} milliseconds`
        );
        return largeObjectDTO;
    };

    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            // TODO: Implement context token for caching.
            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !approvedRoutes.includes(response.url)
            ) {
                return response;
            }

            const start = performance.now();
            // Process all templates
            if (approvedRoutes.includes(response.url)) {
                response = response.clone({
                    body: transformLargeObjects(response.body),
                });
            }
            const end = performance.now();
            logger.debug(
                `Large Object Interceptor took ${end - start} milliseconds`
            );
            return response;
        })
    );
};
