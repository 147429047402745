/**
 * Represents a field validator data transfer object.
 */
export class FieldValidatorDTO {
    /**
     * The type of validation to be performed.
     */
    public validationType!: string;

    /**
     * The value used for validation.
     */
    public validationValue!: string;

    /**
     * An optional message to be displayed if validation fails.
     */
    public validationMessage?: string;
}
