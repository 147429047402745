import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { NotificationDTO } from "../../data/model/notification.dto";
import { environment } from "../../../../environments/environment";
import { inject } from "@angular/core";
import { JsonNotificationMapper } from "../../data/transformation/json-notification.mapper";
import { ILogger } from "../../../core/logging/models/logger.model";
import { LOGGER } from "../../../core/logging/providers/logger.provider";
import { IIndexable } from "../../../core/base/indexable.interface";

/**
 * Intercepts HTTP requests and responses which are related to notifications and transforms the JSON objects to DTOs and vice versa.
 * @param request The request to be intercepted.
 * @param next The next interceptor in the chain.
 * @returns The observable of the HTTP event.
 */
export const notificationInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const mapper: JsonNotificationMapper = inject(JsonNotificationMapper);
    const logger: ILogger = inject(LOGGER);

    /**
     * Transforms the JSON object to a DTO object.
     * @param responseBody The JSON object to be transformed.
     * @returns The transformed DTO object.
     */
    const transformResponseToDTO = (
        responseBody: unknown
    ): NotificationDTO[] => {
        const cloneResponseBody: IIndexable[] = [
            ...(responseBody as IIndexable[]),
        ];
        const transformedBody: NotificationDTO[] = cloneResponseBody.map(
            (json: IIndexable) => mapper.mapFrom(json)
        );
        return transformedBody;
    };

    /**
     * Transforms the DTO object to a JSON object.
     * @param requestBody The DTO object to be transformed.
     * @returns The transformed JSON object.
     */
    const transformRequestToJson = (requestBody: NotificationDTO): unknown => {
        const cloneRequestBody = { ...requestBody };
        const transformedBody: unknown = mapper.mapTo(cloneRequestBody);
        return transformedBody;
    };

    // Return if does not match our interceptor rule
    if (
        !(request instanceof HttpRequest) ||
        request.url === "" ||
        request.url == null ||
        !environment.routes.mCase.routes.getNotices.url.includes(request.url)
    ) {
        return next(request);
    }

    // Transform request body from DTO to JSON
    if (request.method === "PUT" && request instanceof HttpRequest) {
        logger.debug(`Intercepting NotificationDTO PUT request.`);
        request = request.clone({
            body: transformRequestToJson(request.body as NotificationDTO),
        });
        return next(request);
    }

    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            // Return if does not match our interceptor rule
            if (
                !(response instanceof HttpResponse) ||
                response.url === "" ||
                response.url == null ||
                !environment.routes.mCase.routes.getNotices.url.includes(
                    response.url
                )
            ) {
                return response;
            }

            // Transform response body from JSON to DTO
            if (response instanceof HttpResponse) {
                logger.debug(`Intercepting JSON Notification GET request.`);
                response = response.clone({
                    body: transformResponseToDTO(response.body ?? []),
                });
            }

            return response;
        })
    );
};
