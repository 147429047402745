import {
    HttpErrorResponse,
    HttpEvent,
    HttpInterceptorFn,
    HttpResponse,
    HttpStatusCode,
} from "@angular/common/http";
import {
    Observable,
    map,
    catchError,
    TimeoutError,
    throwError,
    filter,
} from "rxjs";
import { NetworkStatusService } from "../../../../shared/infrastructure/services/network-status.service";
import { inject } from "@angular/core";
import { ILogger } from "../../../logging/models/logger.model";
import { LOGGER } from "../../../logging/providers/logger.provider";

/**
 * Server timeout interceptor
 * @param request The request to be intercepted.
 * @param next The next interceptor in the chain.
 * @returns An Observable of HttpEvent
 */
export const serverTimeoutInterceptor: HttpInterceptorFn = (
    request,
    next
): Observable<HttpEvent<unknown>> => {
    const networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);
    const logger: ILogger = inject(LOGGER);

    return next(request).pipe(
        filter((event) => event instanceof HttpResponse),
        map((response: HttpEvent<unknown>) => {
            networkStatusService.setIsServerOnline(true);

            return response;
        }),
        catchError((error: HttpErrorResponse) => {
            if (
                error instanceof TimeoutError ||
                error.status === HttpStatusCode.RequestTimeout ||
                error.status === HttpStatusCode.GatewayTimeout
            ) {
                logger.error("Server request timed out");
                networkStatusService.setIsServerOnline(false);
            }

            return throwError(() => error);
        })
    );
};
