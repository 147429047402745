import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { IMirrorDependencyOption } from "./IMirror-dependency-option";

/**
 * Represents a mirror dependency option.
 */
export class RegularMirrorDependencyOption implements IMirrorDependencyOption {
    /**
     * The name of the field.
     */
    fieldName: string = "";

    /**
     * The ID of the field.
     */
    fieldID: number = 0;

    /**
     * The mirror type option.
     */
    mirrorType: MirrorOptionType = MirrorOptionType.Regular;

    /**
     * The ID of the datalist.
     */
    datalistID: number = 0;

    /**
     * The system name of the datalist.
     */
    datalistSystemName: string = "";

    mirrorLabel!: string;
}
