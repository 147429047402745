import { InjectionToken } from "@angular/core";
import { BooleanUtilityMethods } from "../utilities/type-extensions/boolean-utility-methods.service";
import { DeserializationService } from "../../core/loadProcess/services/deserialization.service";
import { TransformTemplates } from "../../core/loadProcess/services/transform-templates.service";

export const BOOLEAN_UTILITIES = new InjectionToken<BooleanUtilityMethods>(
    "Boolean Utility Methods",
    {
        providedIn: "root",
        factory: () => new BooleanUtilityMethods(),
    }
);
export const DESERIALIZE = new InjectionToken<DeserializationService>(
    "DeserializationService",
    {
        providedIn: "root",
        factory: () => new DeserializationService(),
    }
);
export const TRANSFORM_TEMPLATES = new InjectionToken<TransformTemplates>(
    "Transform Templates",
    {
        providedIn: "root",
        factory: () => new TransformTemplates(),
    }
);
