import {
    Component,
    DestroyRef,
    inject,
    OnDestroy,
    signal,
    WritableSignal,
} from "@angular/core";
import { AuthService } from "./core/auth/infrastructure/services/auth.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NetworkStatusService } from "./shared/infrastructure/services/network-status.service";
import { LOGGER } from "./core/logging/providers/logger.provider";
import { LoadSystemSettingsUsecase } from "./core/system-settings/core/usecases/load-system-settings.usecase";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent implements OnDestroy {
    private authService: AuthService = inject(AuthService);
    private router: Router = inject(Router);
    private _snackBar = inject(MatSnackBar);
    private networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);
    private logger = inject(LOGGER);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private loadSystemSettingsUsecase: LoadSystemSettingsUsecase = inject(
        LoadSystemSettingsUsecase
    );
    isLoading: WritableSignal<boolean> = signal(true);
    isSystemSettingsLoaded: WritableSignal<boolean> = signal(false);
    readonly loadFailureMessage: string =
        "Failed to load the requested resource. Please try again later.";

    constructor() {
        this.userLoggedInSubscription();
        this.loadSystemSettings();
    }

    /**
     * Load system settings
     */
    private async loadSystemSettings(): Promise<void> {
        try {
            this.logger.debug("Loading system settings");
            await this.loadSystemSettingsUsecase.execute();

            this.isSystemSettingsLoaded.set(true);
        } catch (error) {
            this.logger.error("Error while loading system settings", error);

            this._snackBar.open(
                "An error occurred while configuring the system settings.",
                "Close",
                {
                    duration: 5000,
                }
            );

            this.isSystemSettingsLoaded.set(false);
        } finally {
            this.isLoading.set(false);
        }
    }

    ngOnDestroy(): void {
        this.networkStatusService.onDestroy();
    }

    /**
     * Subscription for isUserLoggedIn
     */
    private userLoggedInSubscription(): void {
        this.authService.isUserLoggedIn$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((isUserLoggedIn) => {
                if (isUserLoggedIn) {
                    this.router.navigate(["/home"]);
                }
            });
    }
}
