import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class BooleanUtilityMethods {
    convertToBoolean(value: string | boolean): boolean {
        if (typeof value === "string") {
            return (
                value.toLowerCase() === "true" ||
                value === "1" ||
                value.toLowerCase() === "yes"
            );
        }
        return Boolean(value);
    }
}
